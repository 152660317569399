.header-top {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9988;
  background: var(--main-color);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
}

.navbar {
  padding: 0 0;
  padding-right: 30px;
  background-color: var(--header-bg);

  @include sm {
    padding-right: 1rem;
  }

}

.navbar-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .sidebar-toggle {
    margin-right: 8px;

    img,
    svg {
      width: 18px;
      pointer-events: none;

      g {
        fill: var(--color-dark);
      }
    }

    img {
      width: 18px;
      height: 18px;
    }

    i {
      @include rfs(19px);
      color: var(--color-gray);
    }
  }

  .navbar-brand {
    margin-right: 40px;

    @include lg {
      margin-right: 26px;
    }

    @include md {
      margin-right: 25px;
      margin-left: 0;
    }

    @include xs {
      margin-right: 25px;
      margin-left: 0;
    }

    svg,
    img {
      max-width: 160px;
      min-width: 160px;

      @include ssm {
        max-width: 130px;
        min-width: 130px;
      }

      text {
        span {
          fill: var(--color-dark);
        }
      }
    }

    .light {
      display: none;
    }

    .svg.dark {
      display: block;

      g {
        fill: var(--color-dark);
      }
    }
  }

  form {
    display: flex;
    align-items: center;

    span {
      width: 15px;
      color: var(--color-gray-x);
    }

    input {
      height: 25px;
      border: 0 none;
      box-shadow: none;
      padding-left: 10px;

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-lighten);
      }
    }

    svg,
    img {
      width: 18px;
      height: 18px;
      color: var(--color-gray);
    }
  }

  .customizer-trigger {
    svg {
      path {
        transition: var(--transition);
      }
    }
  }

  .logo-area {
    display: flex;
    align-items: center;
    height: 72px;
    padding-left: 30px;
    background-color: var(--logo-wrapper-bg);
    min-width: 280px;
    justify-content: space-between;

    @include sm {
      padding-left: 1rem;
      height: 60px;
    }

    @include xs {
      min-width: 230px;
    }
  }
}

.navbar-right {
  @include sm {
    margin-right: -8px;
  }
}

.navbar-right__menu {
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 17px;

  >li {
    padding: 20px 12.5px;

    &.nav-author {
      padding-right: 0;
    }

    @include md {
      padding: 20px 9px;
    }

    @include sm {
      padding: 0;

      .dropdown-custom {
        padding: 20px 12px;
      }
    }
  }

  .dropdown-custom .dropdown-wrapper:before {
    @include sm {
      display: none;
    }
  }

  .dropdown-wrapper {
    @include ssm {
      min-width: 100% !important;
      position: fixed !important;
    }

    @include xs {
      width: 100% !important;
    }
  }

  .nav-settings {
    .dropdown-custom {
      .dropdown-wrapper--large {
        @include cMq2(900px) {
          min-width: 100%;
          position: fixed;
        }

        &:before {
          @include cMq2(900px) {
            display: none;
          }
        }
      }
    }

    .nav-item-toggle {
      img {
        width: 20px;
      }

      i {
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }
  }

  .nav-flag-select {
    img {
      width: 20px;
      border-radius: 50%;
    }

    .dropdown-wrapper {
      padding: 5px 0;

      a {
        display: flex;
        align-items: center;
        padding: 7px 20px;
        color: var(--body-color);
        transition: 0.3s ease;
        font-size: 14px;

        &:hover {
          color: var(--color-primary);
          background: rgba(var(--color-primary-rgba), 0.1);
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .nav-search {
    position: relative;

    @include sm {
      display: none;
    }
  }

  .nav-author {
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }

  .nav-item-toggle {
    position: relative;
    color: var(--color-gray);
    display: flex;
    align-items: center;

    span,
    svg {
      width: 20px;
      height: 20px;
    }

    &.icon-active {
      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        right: -2px;
        top: -2px;
        border: 2px solid var(--color-white);
      }
    }
  }

  .nav-message {
    .nav-item-toggle {
      &:before {
        background: $success;
      }
    }

    ul {
      li {
        @include xs {
          // flex-flow: column;
          align-items: flex-start !important;
        }
      }
    }
  }

  .nav-notification {
    .nav-item-toggle {
      &:before {
        background: var(--color-warning);
      }
    }
  }

  .dropdown-wrapper {
    border-radius: 0 0 6px 6px;

    @include e("title") {
      margin: 10px;
      border-radius: 6px;
      background: var(--bg-lighter);
      padding: 15px;
      font-size: 14px;
      text-align: center;
    }

    @include e("more") {
      padding: 15px;
      text-align: center;
      display: block;
      box-shadow: 0 -15px 20px rgba(var(--light-gray-rgba), 0.08);
      color: var(--color-lighten);
      font-size: 13px;
      font-weight: 500;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .nav-message {
    .dropdown-wrapper {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 320px;
        overflow: hidden auto;
        scrollbar-width: thin;
        scrollbar-color: var(--thumbBG) var(--scrollbarBG);

        &::-webkit-scrollbar {
          width: 11px;
        }

        &::-webkit-scrollbar-track {
          background: var(--scrollbarBG);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--thumbBG);
          border-radius: 6px;
          border: 3px solid var(--scrollbarBG);
        }

        li {
          padding: 15px 25px;
          display: flex;
          align-items: center;
          transition: var(--transition);
          position: relative;

          @include xs {
            padding: 10px 25px;
          }

          &:hover {
            box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
          }
        }
      }

      .user-avater {
        margin-right: 15px;
        position: relative;

        @include xs {
          margin: 0 10px 10px 0;
        }

        &:before {
          position: absolute;
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid var(--color-white);
          right: 0;
          bottom: 0;
        }
      }

      .author-online {
        .user-avater {
          &:before {
            background: $success;
          }
        }
      }

      .author-offline {
        .user-avater {
          &:before {
            background: var(--color-warning);
          }
        }
      }

      .user-message {
        flex: 1;

        @include xs {
          width: 100%;
        }
      }

      .user-avater img {
        width: 40px;
        border-radius: 50%;
      }

      .user-message p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        margin: 0;

        @include xs {
          .msg-count {
            margin-left: 10px;
          }
        }
      }

      .subject {
        color: var(--color-dark);
      }

      span.time-posted {
        font-size: 12px;
      }

      .has-new-message {
        .time-posted {
          color: $success;
        }
      }
    }
  }

  .nav-notification {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 320px;
      overflow: hidden auto;
      scrollbar-width: thin;
      scrollbar-color: var(--thumbBG) var(--scrollbarBG);

      &::-webkit-scrollbar {
        width: 11px;
      }

      &::-webkit-scrollbar-track {
        background: var(--scrollbarBG);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG);
        border-radius: 6px;
        border: 3px solid var(--scrollbarBG);
      }
    }

    @include e("single") {
      padding: 15px 25px;
      transition: var(--transition);
      position: relative;

      &:hover {
        box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
      }
    }

    @include e("type") {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 15px;

      span {
        font-size: 15px;
      }

      img,
      svg {
        width: 15px;
      }

      @include m("primary") {
        color: var(--color-primary);
        background: rgba(var(--color-primary-rgba), .10);
      }

      @include m("secondary") {
        color: var(--color-secondary);
        background: rgba(var(--color-secondary-rgba), .10);
      }

      @include m("success") {
        color: $success;
        background: rgba(var(--color-success-rgba), .10);
      }

      @include m("info") {
        color: var(--color-info);
        background: rgba(var(--color-info-rgba), .10);
      }

      @include m("danger") {
        color: var(--color-danger);
        background: rgba(var(--color-danger-rgba), .10);
      }
    }

    @include e("details") {
      flex: 1;

      p {
        margin-bottom: 5px;
        font-size: 14px;

        &:last-child {
          margin: 0;
        }
      }

      .time-posted {
        font-size: 12px;
      }
    }
  }

  .nav-support {
    .dropdown-wrapper {
      padding: 30px;
    }

    .list-group {
      span {
        color: var(--color-light);
        font-size: 14px;
        display: block;
        margin-bottom: 5px;
      }

      ul {
        margin: 0 0 15px 0;
        padding: 0 0 0 15px;
        list-style: none;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          margin-bottom: 10px;

          a {
            color: var(--color-dark);
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .nav-author {
    .nav-item__title {
      margin-left: 10px;
      color: var(--color-gray);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      width: auto;
      height: auto;

      @include ssm {
        display: none;
      }

      .nav-item__arrow {
        color: var(--color-light);
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .dropdown-wrapper {
      min-width: 300px;

      @include sm {
        min-width: 100%;
        position: fixed;
      }
    }

    @include e("info") {
      background: var(--bg-lighter);
      border-radius: 8px;
      margin: 10px;
      display: flex;
      align-items: center;
      padding: 20px 25px;

      .author-img {
        margin-right: 15px;

        img {
          max-width: 40px;
        }
      }

      h6 {
        font-weight: 500;
        font-size: 14px;
      }

      span {
        font-size: 13px;
      }
    }

    @include e("signout") {
      font-size: 13px;
      background: var(--bg-lighter);
      padding: 18px 15px;
      color: #868eae;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;

      img,
      svg {
        width: 15px;
      }

      i,
      span {
        font-size: 15px;
        margin-right: 6px;
      }
    }

    @include e("options") {
      ul {
        list-style: none;
        margin: 0;
        padding: 0 0 15px;

        li {
          padding: 0;

          a {
            font-size: 14px;
            display: block;
            padding: 9px 25px 9px;
            color: var(--color-light);
            transition: var(--transition);

            i,
            svg,
            span {
              margin-right: 12px;
            }

            i {
              font-size: 18px;
            }

            img,
            svg {
              width: 16px;
            }

            &:hover {
              background: rgba(var(--color-primary-rgba), 0.05);
              padding-left: 35px;
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }
}

.navbar-right__menu {
  .search-toggle {
    &.active {
      i.uil-times {
        display: block;
      }

      i.uil-search {
        display: none;
      }
    }

    i,
    img,
    svg {
      font-size: 20px;
      color: var(--color-light);
    }

    i.uil-times {
      display: none;
    }

    i.uil-search {
      display: block;
    }
  }
}

.search-form-topMenu {
  display: none;
  position: absolute;
  padding: 0 15px;
  min-width: 320px;
  top: 4px;
  right: 40px;
  height: 50px;
  background-color: var(--color-white);
  border: 1px solid var(--border-light);
  border-radius: 6px;
  top: 50%;
  transform: translate(0, -50%);

  @include lg {
    top: 90px;
    right: 0;
  }


  &.show {
    display: flex;
    align-items: center;
  }

  .search-icon {
    width: 18px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 0 none;
    padding-left: 30px;
  }
}

.navbar-right__mobileAction {
  a {
    padding: 15px 0;
    display: inline-block;

    svg,
    img {
      width: 20px;
      color: var(--color-light);

      @include sm {
        width: 18px;
      }
    }
  }

  a+a {
    margin-left: 15px;

    @include ssm {
      margin-left: 10px;
    }
  }

  .btn-search {
    &.search-active {
      .feather-search {
        display: none;
      }

      .feather-x {
        display: inline-block;
      }
    }

    .feather-x {
      display: none;
      transition: var(--transition);
    }
  }
}

.mobile-search,
.mobile-author-actions {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 60px;
  z-index: 999;
  display: flex;
  align-items: center;
  transform: translateY(-80px);
  transition: 0.35s;
  box-shadow: 0 2px 30px rgba(146, 153, 184, 0.063);
  background-color: var(--color-white);
  visibility: hidden;
  opacity: 0;

  &.show {
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;

    .search-form {
      display: flex;
    }
  }

  .search-form {
    display: none;
    align-items: center;
    width: 100%;

    .form-control {
      border: 0 none;
    }

    svg,
    img {
      margin-left: 15px;
      width: 20px;
    }
  }
}

.mobile-author-actions {
  padding: 0 15px;
  display: flex;

  .navbar-right__menu {
    width: 100%;
    justify-content: flex-end;
    margin: 0;
  }
}

.list-settings {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  @include xs {
    height: 300px;
    overflow-y: auto;
  }

  li {
    width: 50%;
    flex: 0 0 50%;
    padding: 20px;
    transition: var(--transition);
    position: relative;

    @include xs {
      padding: 10px;
      width: 100%;
      flex: 0 0 100%;
      flex-flow: column;

      .me-3 {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &:hover {
      background: var(--color-white);
      box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.15);
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    a {
      color: var(--color-dark);
    }

    h6 {
      font-weight: 500;
    }
  }
}

// Navbar Menu
.hexadash-top-menu {
  @include md {
    display: none;
  }

  ul {
    li {
      display: inline-block;
      position: relative;
      padding-right: 14px;

      @media only screen and (max-width: 1024px) {
        $padding-right: 10px;
      }

      &:not(:last-child) {
        margin-right: 34px;

        @media only screen and (max-width: 1300px) {
          margin-right: 30px;
        }

        @media only screen and (max-width: 1199px) {
          margin-right: 26px;
        }

        @media only screen and (max-width: 1024px) {
          margin-right: 16px;
        }
      }

      &.has-subMenu {
        >a {
          position: relative;

          &:before {
            font-size: 12px;
            position: absolute;
            right: -16px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 600;
            font-family: "Line Awesome Free";
            content: "\f107";
            line-height: 1;
            color: var(--color-light);
          }
        }
      }

      &.has-subMenu-left {
        >a {
          position: relative;

          &:before {
            font-size: 12px;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 600;
            font-family: "Line Awesome Free";
            content: "\f105";
            line-height: 1;
            color: var(--color-light);
          }
        }
      }

      &:hover {
        >.subMenu {
          top: 65px;
          opacity: 1;
          visibility: visible;
        }
      }

      a {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 1.657rem 0;
        color: var(--color-gray);
        position: relative;

        svg,
        img,
        i,
        .nav-icon {
          margin-right: 14px;
          width: 16px;
          font-size: 18px;
        }

        .menuItem {
          position: absolute;
          right: 52px;
          top: 50%;
          transform: translateY(-50%);
          height: auto;
          font-size: 10px;
          font-weight: 700;
          border-radius: 3px;
          min-width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
        }
      }

      >ul {
        li {
          display: block;
          position: relative;
          padding-right: 0;
          margin-right: 0 !important;
          &.active{
            a{
              background-color: rgba(var(--color-primary-rgba), 0.06);
              padding-left: 40px;
              color: var(--color-primary);
            }
          }

          a {
            font-weight: 400;
            padding: 0 30px;
            line-height: 3;
            color: var(--color-light);
            transition: 0.3s;

            &:hover,
            &.active {
              background-color: rgba(var(--color-primary-rgba), 0.06);
              padding-left: 40px;
              color: var(--color-primary);
            }
          }

          &:hover {
            .subMenu {
              top: 0;
              left: 250px;

              @media only screen and (max-width: 1300px) {
                left: 180px;
              }
            }
          }
        }
      }
    }
  }

  .subMenu {
    width: 250px;
    background: var(--color-white);
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 80px;
    padding: 12px 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    z-index: 98;
    box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);

    @media only screen and (max-width: 1300px) {
      width: 180px;
    }

    .subMenu {
      width: 250px;
      background: var(--color-white);
      position: absolute;
      left: 250px;
      top: 0;
      padding: 12px 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      z-index: 98;
      box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);

      @media only screen and (max-width: 1300px) {
        width: 200px;
        left: 180px;
      }
    }
  }
}

// Mega Menu
.hexadash-top-menu {
  >ul {
    >li {
      &:hover {
        .megaMenu-wrapper {
          opacity: 1;
          visibility: visible;
          z-index: 99;
        }
      }

      &.mega-item {
        position: static;
      }

      a {
        &.active {
          &:before {
            color: var(--color-primary) !important;
          }

          color:var(--color-primary);
        }
      }

      .megaMenu-wrapper {
        display: flex;
        position: absolute;
        text-align: left;
        left: 0;
        top: 100%;
        overflow: hidden;
        z-index: -1;
        padding: 16px 0;
        box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
        border-radius: 0 0 6px 6px;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
        background-color: var(--color-white);

        &.megaMenu-small {
          width: 500px;

          >li {
            flex: 0 0 50%;
          }

          ul {
            li {
              >a {
                padding: 0 45px;
                position: relative;

                &:after {
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  position: absolute;
                  left: 30px;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: #c6d0dc;
                  content: "";
                  transition: 0.3s;
                }

                &:hover,
                &.active {
                  padding-left: 45px;
                  color: var(--color-primary);

                  &:after {
                    background-color: var(--color-primary);
                  }
                }
              }
            }
          }
        }

        &.megaMenu-wide {
          width: 1000px;
          padding: 5px 0 18px;

          @media only screen and (max-width: 1300px) {
            width: 800px;
          }

          >li {
            position: relative;
            flex: 0 0 25%;

            &:hover {
              &:after {
                opacity: 1;
                visibility: visible;
              }
            }

            .mega-title {
              display: inline-block;
              margin: 20px 0 14px 0;
              position: relative;
              font-size: 14px;
              font-weight: 500;
              padding-left: 45px;
              color: var(--color-dark);

              &:after {
                position: absolute;
                height: 5px;
                width: 5px;
                border-radius: 50%;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #c6d0dc;
                content: "";
              }
            }
          }
        }

        &.megaMenu-dropdown {
          width: 540px;
          padding: 5px 0 18px;
          overflow: initial;

          @media only screen and (max-width: 1300px) {
            width: 340px;
          }

          >li {
            flex: 0 0 50%;
          }

          .subMenu {
            left: 250px;
            top: 0;
          }
        }

        &:not(.megaMenu-dropdown) {
          ul {
            li {
              position: relative;

              &:hover {
                >a {
                  padding-left: 45px;
                }
              }

              >a {
                line-height: 3;
                color: var(--color-light);
                font-weight: 400;
                transition: 0.3s;
              }

              &:after {
                width: 6px;
                height: 1px;
                border-radius: 50%;
                position: absolute;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                transition: 0.3s;
                opacity: 0;
                visibility: hidden;
                background-color: var(--color-light);
              }
            }
          }
        }
      }
    }
  }
}

.header-top{
  .navbar-right__menu{
    .nav-notification{
      .nav-item-toggle{
        svg,img{
          -webkit-animation: ring 2s ease infinite;
          animation: ring 2s ease infinite;
        }
        &:after,
        &:before{
          z-index: 1;
        }
      }
    }
  }
}
