/*
Template Name: HexaDash
Template URI: 
Author: SovWare
Author URI: 
Version: 1.1.0
NB: this file contains the list of components/scss partial along with the style.scss contents order.

*/
/**                              **
**       TABLE OF CONTENTS       **
**                               **
-----------------------------------*/
/*

  01.COMPONENTS
    -- 404
    -- add-product
    -- admin-page
    -- alert
    -- app-ui
    -- avatar
    -- badge
    -- banner
    -- blog
    -- bookmark
    -- breadcrumb
    -- button
    -- calendar
    -- card-charts
    -- card-social
    -- cards
    -- carousel
    -- cart
    -- chat
    -- checkbox
    -- checkout
    -- circle-progress
    -- collapse
    -- coming-soon
    -- comments
    -- course
    -- dark-mode
    -- dashboard-base
    -- data-table
    -- data-picker
    -- drag-drop
    -- drawer
    -- dropdown
    -- empty
    -- faq
    -- fileM
    -- footer
    -- form-layouts
    -- form
    -- gallery
    -- grid
    -- icon
    -- import
    -- input
    -- job
    -- kanban-board
    -- knowledgeable
    -- list
    -- menu
    -- message
    -- modal
    -- navbar
    -- note
    -- notification
    -- order
    -- pageHeader
    -- pagination
    -- preloader
    -- pricing
    -- profile-feature
    -- profile
    -- progress-bar
    -- projects
    -- radio
    -- rate
    -- result
    -- search
    -- select
    -- shop
    -- sidebar
    -- skeleton
    -- slider
    -- spins
    -- statistics
    -- step
    -- support-ticket
    -- switch
    -- tab
    -- table
    -- tables
    -- tag
    -- task-app
    -- team
    -- term
    -- testimonial
    -- time-picker
    -- timeline
    -- todo
    -- tooltip
    -- upload
    -- users

  02.ELEMENTS
    -- customizer
    -- date-ranger
    -- social

  03.GENERAL
    -- general (Common)
    -- kewFrames (Common)
    -- utility-classes (Common)

  04.GENERAL
    -- btn-shadow (Common)
    -- btn-transparent (Common)
    -- buttons (Common)
    -- color-classes (Common)
    -- custom-scrollbar (Common)
    -- form (Common)
    -- functions (Common)
    -- gradient (Common)
    -- helpers (Common)
    -- hover (Common)
    -- media-queries (Common)
    -- overlay (Common)
    -- gradient (Common)
    -- utility-classes (Common)

  05. PAGES
    -- avatar
    -- badges
    -- buttons
    -- card
    -- contact
    -- email
    -- map
    -- social-dashboard
    -- maintenance
  
  -- responsive (Common)

***************************/
