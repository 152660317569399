.testimonial-section {
    border-radius: 10px;
    margin-bottom: 50px;

    @include e(padding) {
        padding: 85px 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        @include xs{
            padding: 40px 0 60px 0;
        }
    }
}


.testimonial-slider-global {

    .card {
        border-radius: 8px;
    }

    .author-thumb {
        p {
            @include ofs(15px, lh(15px, 22px), 500);
            color: var(--color-dark);
            margin: 16px 0 0 0;
        }

        span {
            @include ofs(13px, lh(13px, 22px), 400);
            color:var(--color-gray);
            color: rgba(var(--color-gray-rgba), .70);
            margin: 4px 0 0 0;
        }
    }

    .author-comment {
        @include ofs(16px, lh(16px, 28px), 400);
        color:var(--color-gray);
    }

    /* Slick Slider */

    .slick-slide {
        margin: 40px 15px;
        outline: none;
    }


    /* Arrow */
    .slider-prev {
        left: -60px;
    }

    .slider-next {
        right: -60px;
    }

    .slider-arrow {
        position: absolute;
        top: 50%;
        margin-top: -18px;
        color: var(--color-light);
       @include rfs(18px);
        cursor: pointer;
        z-index: 10;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        @extend .content-center;
        box-shadow: 0 3px 10px rgba(var(--light-gray-rgba), .16);
        background-color: white;

        @include sm() {
            top: 100%;

            &.slider-prev {
                left: 30px;
            }

            &.slider-next {
                right: 30px;
            }
        }
    }

    /* Dots */

    .slick-dots li button {
        &:before {
            color: var(--color-white); //Dots color
            font-size: 10px; //Dots Size
        }
    }

    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }

    &.slick-slider .slick-dots button {
        width: 10px;
        height: 10px;
    }

    &.slick-slider .slick-dots li {
        button {
            background: rgba(var(--color-primary-rgba), .20);
        }

        &.slick-active button {
            width: 10px;
            height: 10px;
            background-color: var(--color-primary);
        }
    }
}


.testimonial-slider-global {
    &.testimonial-slider1 {
        .card {
            border-radius: 8px;
            border: none;
            transition: var(--transition);
            box-shadow: none;
        }

        .card-body {
            text-align: center;
            padding: 40px;
            box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), .10);
            border-radius: 8px;

            .slick-initialized .slick-slide {
                outline: none;
            }

            .author-thumb {
                span {
                    margin-bottom: 24px;
                }
            }
        }

        .slick-slide {

            &.slick-center {
                .card-body {
                    box-shadow: 0 20px 30px rgba(var(--light-gray-rgba), .20);
                }
            }
        }
    }

    &.testimonial-slider2 {
        .author-comment {
            margin-top: 22px;
            margin-bottom: 0;
        }

        .card {
            box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), .10);
        }

        .card-body {
            padding: 40px 30px;
        }
    }

    &.testimonial-slider3-nav {
        margin-top: 50px;

        .slick-slide {
            transition: transform .4s;
            position: relative; 
          }
          
          .slick-slide:after {
            content:'';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: transform .4s;
          }
          
          .slick-slide {
            transform: scale(0.7)  translate(640px);
          }
          
          .slick-slide.slick-active {
            transform: scale(0.9) translate(225px);
            @include xl{
                transform: scale(0.9) translate(150px);
            }
          }
          
          .slick-slide.slick-center + .slick-slide {
            transform: scale(0.9) translate(-225px);
            z-index: 10;
            @include xl{
                transform: scale(0.9) translate(-150px);
            }
          }
          
          .slick-slide.slick-center + .slick-slide + .slick-slide {
            transform: scale(0.7)  translate(-640px);
            z-index: 5;
          }
          
          .slick-slide.slick-center {
            /* margin: 0 -10%; */
            transform: scale(1);
            z-index: 30;
            @include xl{
                transform: scale(1);
            }
          }
          
          .slick-center:after {
            opacity: 0;
          }

        .slick-list {
            height: 100px;
            transform: none;
        }

        .slick-slide {
            margin: 15px;
            cursor: move;
        }

        img {
            min-width: 70px;
            min-height: 70px;
            opacity: .4;
        }

        .slick-center {
            img {
                min-width: 100px;
                min-height: 100px;
                opacity: 1;
            }
        }

        .slick-track {
            display: flex;
            align-items: center;
            transition: 0.3s;
            .slick-slide:not(.slick-active){
                img{
                    opacity: 0;
                }
            }
        }

    }

    &.testimonial-slider3-for {
        .slick-slide {
            margin: 20px 15px 0 15px;
            cursor: move;
        }

        .card-body {
            padding-bottom: 0;
        }
    }

    &.testimonial-slider4 {
        .author-comment {
            margin-top: 25px;
        }

        .card-body {
            padding: 50px;
        }

    }
}