/* Style 1 */
.blog-card {
    box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 25px 25px 30px 25px;

    @include e(thumbnail) {
        width: 100%;
        height: 220px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(var(--color-dark-rgba), .10);
            left: 0;
            top: 0;
        }

        img {
            border-radius: 10px;
            width: 100%;
            height: 220px;
            object-fit: cover;
        }
    }

    @include e(title--top) {
        font-size: 15px;
        font-weight: 400;
        color: var(--color-light);
        margin-top: 11px;
    }

    @include e(title) {
        margin-top: 13px;
        margin-bottom: 8px;

        a {
            font-weight: 600;
            color: var(--color-dark);
            @include rfs(20px);

            &:hover {
                color: var(--color-primary);
            }
        }
    }

    @include e(content) {
        p {
            font-size: 16px;
            font-weight: 400;
            color: var(--color-gray);
            margin-bottom: 15px;
        }
    }

    @include e(meta) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: -5px;
        >div{
            margin: 5px;
        }
    }

    @include e(meta-count) {
        ul {
            display: flex;
            align-items: center;

            li {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    @include e(meta-reaction) {
        display: flex;
        align-items: center;
        img,
        svg {
            width: 14px;
        }
    }

    @include e(meta-reaction-like) {
        font-size: 13px;
        font-weight: 400;
        color: var(--color-light);
        margin-left: 5px;
    }

    @include e(meta-doc-wrapper) {
        display: flex;
        align-items: center;
        img,
        svg {
            width: 14px;
        }

        .blog-card__meta-doc {
            font-size: 13px;
            font-weight: 400;
            color: var(--color-light);
            margin-left: 5px;
        }
    }

    @include e(meta-profile) {
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
        }

        span {
            font-size: 15px;
            font-weight: 400;
            color: var(--color-light);
            text-transform: capitalize;
        }
    }

    @include e(header-top) {
        margin-top: 11px;

        .read,
        .category,
        .date {
            font-size: 15px;
            font-weight: 400;
            color: var(--color-light);
        }

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 -8px;

            li {
                position: relative;
                margin: 0 8px;

                &:not(:last-child):after {
                    content: "";
                    position: absolute;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 3px;
                    width: 3px;
                    border-radius: 50%;
                    background-color: var(--color-light);
                }
            }
        }
    }

    &--2 {
        padding: 0;

        .blog-card__thumbnail {
            padding: 0;
            height: 280px;
            border-radius: 10px 10px 0 0;

            @include ssm {
                height: 220px;
            }

            img {
                height: 280px;
                border-radius: 10px 10px 0 0;

                @include ssm {
                    height: 220px;
                }
            }
        }

        .blog-card__details {
            padding: 15px 25px;
        }

        .blog-card__title {
            margin-top: 20px;
            margin-bottom: 16px;
        }

        .blog-card__content {
            p {
                line-height: lh(16px, 27px);
                margin-bottom: 13px;
            }
        }
    }

    &--3 {
        padding: 0;

        .blog-card__thumbnail {
            padding: 0;
            height: 280px;
            border-radius: 10px 10px 0 0;

            @include ssm {
                height: 220px;
            }

            img {
                height: 280px;
                border-radius: 10px 10px 0 0;

                @include ssm {
                    height: 220px;
                }
            }
        }

        .blog-card__details {
            padding: 15px 25px;
        }

        .blog-card__title {
            margin-top: 17px;
            margin-bottom: 16px;
        }

        .blog-card__content {
            p {
                line-height: lh(16px, 27px);
                margin-bottom: 13px;
            }
        }
    }
}

.blog-page2 {
    .breadcrumb-main {
        margin-bottom: 34px;
        margin-top: 31px;
    }
}


/* blog details */
.blog-details-thumbnail {
    img {
        width: 100%;
        max-width: 100%;
        min-height: 450px;
        max-height: 450px;
        object-fit: cover;
        border-radius: 10px;
        @include sm{
            max-height: 300px;
            min-height: 300px;
        }

        @include ssm {
            max-height: 250px;
            min-height: 250px;
        }
    }
}

.blog-details {
    padding: 0 100px;

    @include md {
        padding: 0 70px;
    }

    @include ssm {
        padding: 0;
    }
}

.blog-details-meta {
    list-style: none;
    margin: 0 -10px 48px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include ssm {
        margin: 0 -10px 28px;
    }

    li {
        display: flex;
        align-items: center;
        padding: 0 10px;
        position: relative;

        &:not(:last-child):before {
            position: absolute;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: var(--color-lighten);
            right: -4px;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        a {
            font-size: 15px;
            font-weight: 400;
            color: var(--color-light);

            &:hover {
                color: var(--color-secondary);
            }

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
                object-fit: cover;
                @include ssm{
                    width: 40px;
                    height: 40px;
                }
            }
        }

        &.blog-read-time {
            font-size: 15px;
            font-weight: 400;
            color: var(--color-light);
        }

    }

    .blog-author {
        a {
            span {
                font-size: 16px;
                font-weight: 500;
                line-height: lh(16px, 20px);
                color: var(--color-dark);
                transition: var(--transition);
            }

            &:hover {
                span {
                    color: var(--color-secondary);
                }
            }
        }
    }
}

.blog-details-content {
    margin-top: 53px;

    @include ssm {
        margin-top: 33px;
    }
}

.blog-body {
    p {
       @include rfs(18px);
        font-weight: 400;
        line-height: lh(18px, 30px);
        color: var(--color-gray);
    }

    ul {
        margin-left: 6px;

        li {
            padding-left: 30px;
            margin-bottom: 20px;
            position: relative;
           @include rfs(18px);
            font-weight: 400;
            color: var(--color-gray);
            text-transform: capitalize;

            strong {
                color: var(--color-dark);
                font-weight: 500;
            }

            &:before {
                position: absolute;
                left: 10px;
                top: 11px;
                width: 6px;
                min-width: 6px;
                height: 6px;
                border-radius: 50%;
                transform: translateY(-50%);
                content: "";
                background-color: var(--color-dark);
            }
        }
    }

    ol {
        margin-left: 6px;

        li {
            margin-bottom: 20px;
            position: relative;
           @include rfs(18px);
            font-weight: 400;
            color: var(--color-gray);
            text-transform: capitalize;

            strong {
                color: var(--color-dark);
                font-weight: 500;
            }

            &::marker {
                color: var(--color-dark);
            }

            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }
    }
}

.blog-tags {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -6px;

        li {
            margin: 6px;

            a {
                font-size: 16px;
                font-weight: 400;
                border-radius: 5px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-height: 34px;
                padding: 0 10px;
                text-decoration: none;
                line-height: normal;
                color: var(--color-gray);
                background-color: var(--bg-lighters);

                &:hover {
                    background-color: var(--color-primary);
                    color: var(--color-white);
                }
            }
        }
    }
}

.blog-share {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;

    span {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-dark);
        white-space: nowrap;
        text-transform: capitalize;
        margin: 10px 18px 10px 0;
    }

    ul {
        margin: -5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li {
            margin: 5px;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                min-height: 37px;
                padding: 0 16px;
                border-radius: 5px;
                text-decoration: none;
                border: 1px solid var(--border-color);
                background-color: var(--bg-white);
                color: var(--color-gray);
                text-transform: capitalize;
                transition: var(--transition);

                &:hover {
                    color: var(--color-white);
                }

                i {
                    transition: var(--transition);
                    font-size: 16px;
                    margin-right: 3px;
                }
            }

            .facebook {
                i {
                    color: var(--color-facebook);
                }

                &:hover {
                    background-color: var(--color-facebook);

                    i {
                        color: var(--color-white);
                    }
                }
            }

            .twitter {
                i {
                    color: var(--color-twitter);
                }

                &:hover {
                    background-color: var(--color-twitter);

                    i {
                        color: var(--color-white);
                    }
                }
            }

            .pinterest {
                i {
                    color: var(--color-pinterest);
                }

                &:hover {
                    background-color: var(--color-pinterest);

                    i {
                        color: var(--color-white);
                    }
                }
            }

            .linkedin {
                i {
                    color: var(--color-linkedin);
                }

                &:hover {
                    background-color: var(--color-linkedin);

                    i {
                        color: var(--color-white);
                    }
                }
            }

            .copy-to-clickboard {
                i {
                    color: var(--color-copy);
                }

                &:hover {
                    background-color: var(--color-secondary);

                    i {
                        color: var(--color-white);
                    }
                }
            }
        }
    }
}

.blog-block-quote {
    padding: 17px 22px 15px 45px;
    margin-left: 15px;
    background-color: transparent;
    border-left: 2px solid rgba(var(--color-primary-rgba), 0.5);
    margin-bottom: 50px;

    @include ssm {
        margin-bottom: 30px;
        padding: 17px 22px 15px 35px;
    }

    p {
        line-height: 1.63;
        color: var(--color-dark);
        position: relative;
        margin-bottom: 0;
        @include rfs(20px);
    }

    cite {
        font-style: normal;
        position: relative;
       @include rfs(18px);
        font-weight: 600;
        padding-left: 32px;
        color: var(--color-dark);
        margin-top: 14px;
        display: inline-flex;
        align-items: center;
        @include sm{
            flex-wrap: wrap;
        }

        span {
           @include rfs(18px);
            font-weight: 400;
            line-height: lh(18px, 22px);
            color: var(--color-gray);
            margin-left: 6px;
            @include sm{
                margin-left: 0;
                margin-top: 6px;
            }
        }

        &:before {
            position: absolute;
            left: 0;
            top: 50%;
            width: 20px;
            height: 2px;
            transform: translateY(-50%);
            content: "";
            z-index: 22;
            background-color: var(--border-deep);
        }
    }

    @include e(wrapper) {
        position: relative;

        .top-icon {
            position: absolute;
            top: 0;
            left: -20px;
            img,
            svg {
                width: 15px;
                height: auto;
            }
        }

        p {
            &:after {
                display: inline-block;
                content: ' ';
                background-image: url('img/svg/bottomSemicolon.svg');
                background-size: 15px 28px;
                height: 28px;
                width: 28px;
                z-index: 1;
                background-repeat: no-repeat;
                vertical-align: middle;
            }

        }
    }

}

.blog-author-box {
    padding: 35px;
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: 0 10px 40px rgba(var(--color-lighten-rgba), 0.20);
    display: flex;
    margin: 50px 0 100px 0;

    .auth-img {
        margin-right: 20px;

        img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;
            @include ssm{
                width: 50px;
                height: 50px;
            }
        }

    }

    .auth-details {
        span {
            font-size: 15px;
            font-weight: 400;
            color: var(--color-light);
            margin-bottom: 3px;
            display: inline-block;
        }

        h2 {
           @include rfs(18px);
            font-weight: 600;
            color: var(--color-dark);
        }

        p {
            font-size: 16px;
            line-height: lh(16px, 27px);
            font-weight: 400;
            color: var(--color-gray);
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

.blog-details {
    .blog-share-top {
        position: fixed;
        top: 0;
        right: 0;
        margin-right: 15%;
        transform: translateY(160px);
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
        @include sm{
            display: none;
        }


        &.show {
            transform: translateY(140px);
            opacity: 1;
            visibility: visible;
        }

        .blog-share-fixed {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                font-size: 16px;
                font-weight: 500;
                color: #5C637E;
                white-space: nowrap;
                text-transform: capitalize;
                margin: 12px;
            }
            ul {
                display: inline-flex;
                flex-direction: column;

                li {
                    margin-bottom: 10px;
    
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        border-radius: 50%;
                        text-decoration: none;
                        border: 1px solid var(--border-color);
                        background-color: var(--bg-white);
                        color: var(--color-gray);
                        text-transform: capitalize;
                        transition: var(--transition);
                        width: 50px;
                        height: 50px;
    
                        &:hover {
                            color: var(--color-white);
                        }
    
                        i {
                            transition: var(--transition);
                           @include rfs(18px);
                        }
                    }
    
                    .facebook {
                        i {
                            color: var(--color-facebook);
                        }
    
                        &:hover {
                            background-color: var(--color-facebook);
    
                            i {
                                color: var(--color-white);
                            }
                        }
                    }
    
                    .twitter {
                        i {
                            color: var(--color-twitter);
                        }
    
                        &:hover {
                            background-color: var(--color-twitter);
    
                            i {
                                color: var(--color-white);
                            }
                        }
                    }
    
                    .pinterest {
                        i {
                            color: var(--color-pinterest);
                        }
    
                        &:hover {
                            background-color: var(--color-pinterest);
    
                            i {
                                color: var(--color-white);
                            }
                        }
                    }
    
                    .linkedin {
                        i {
                            color: var(--color-linkedin);
                        }
    
                        &:hover {
                            background-color: var(--color-linkedin);
    
                            i {
                                color: var(--color-white);
                            }
                        }
                    }
    
                    .copy-to-clickboard {
                        i {
                            color: var(--color-copy);
                        }
    
                        &:hover {
                            background-color: var(--color-secondary);
    
                            i {
                                color: var(--color-white);
                            }
                        }
                    }
                }
            }
        }
    }
}