.card-overview {
  .br-1 {
    border-right: 1px solid var(--border-color);

    @include lg {
      border: 0 none;
    }
  }

  @include e("left") {
    .col-xl-6 {
      padding-right: 12.5px;

      @include sm {
        padding-right: 12.5px;
      }

      &.br-1 {
        padding-right: 25px;
        padding-left: 12.5px;

        @include lg {
          padding-right: 12.5px;
        }
      }
    }
  }

  @include e("right") {
    margin-right: 3px;

    .col-xl-6 {
      padding-left: 25px;

      @include lg {
        padding-left: 12.5px;
      }

      @include sm {
        padding-right: 12.5px;
      }

      &.co-last {
        padding-right: 12.5px;
        padding-left: 12.5px;
      }
    }
  }
}

/* overview single chart */

.overview-content{
  background-color: var(--color-white);
}
.overview-single {
  padding: 30px 0 25px;

  .overview-content {
    p {
      font-size: 14px;
      margin-top: 2px;
      color: var(--color-lighten);
    }

    small {
      font-size: 13px;
      margin-left: 6px;
      color: var(--color-lighten);
    }
    img,
    svg {
      width: 15px;
      margin-right: 6px;
    }

    strong {
      font-weight: 500;
    }
  }

  @include e("chart") {
    margin-top: 55px;

    @include sm {
      margin-top: 25px;
    }
  }
}

.ap-po-timeChart {
  .overview-single__chart {
    margin-top: 16px;
  }

  .parentContainer {
    position: relative;
    bottom: -6px;
  }
}

/* Card Bar chart */
.card-chart-bar {
  align-items: flex-end;
  margin-bottom: 25px;
}

.legend-static {
  li {
    font-size: 13px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 7px;
    }

    &.custom-label {
      display: inline-flex;
      align-items: center;
    }
  }
}

/* bar chart top */
.card-bar-top {
  p {
    margin-bottom: 6px;
    color: var(--color-lighten);
  }

  .card-bar-info {
    sub {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 0 6px;

      svg,
      img {
        width: 14px;
      }
    }
  }
}

/* performance chart */
.perfomence-chart {
  .legend-static {
    margin-top: 20px;
  }
}

.performance-stats {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  div {
    padding: 20px;
    transition: var(--transition);
    position: relative;

    @include xl {
      padding: 10px;
    }

    @include xxs {
      flex: 0 0 100%;
      max-width: 100%;
    }

    span {
      color: var(--color-lighten);
      font-size: 14px;
      margin-bottom: 13px;
    }

    strong {
      @include rfs(24px);
      font-weight: 600;
      color: var(--color-dark);

      @include xl {
        @include rfs(20px);
      }

      sub {
        bottom: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &:hover {
      box-shadow: 0 15px 30px rgba(var(--light-gray-rgba), 0.15);
      z-index: 1;

      span {
        color:var(--color-primary);
      }
    }
  }

  span,
  strong {
    display: block;
  }

  a {
    &.active {

      .performance-stats__up,
      .performance-stats__down {
        background:var(--bg-lighter);

        &:hover {
          box-shadow: 0 15px 30px rgba(var(--light-gray-rgba), 0) !important;
        }
      }
    }
  }

  @include e("up") {
    background: var(--color-white);

    strong {
      sub {
       color:var(--color-success);
      }
    }
  }

  @include e("down") {
    strong {
      sub {
        color:var(--color-danger);
      }
    }
  }
}

// Session Pie Chart
.demo3{
  .device-pieChart-wrap {
    .pie-chart-legend{
      top: 53%;
    }
  }
}
.device-pieChart-wrap {
  .pie-chart-legend {
    position: absolute;
    top: 51%;
    left: 50%;
    text-align: center;
    width: 200px;
    margin-bottom:0;
    display: inline-block;
    transform: translate(-50%, -50%);

    p {
      margin-bottom: 0;
      font-size: 15px;
      color:var(--color-gray);
      display: inline-flex;
      flex-direction: column;
      line-height: 28px;
    }

    span {
      @include rfs(30px);
      display: block;
      font-weight: 600;
      color: var(--color-dark);

      @include ssm {
        @include rfs(20px);
      }
    }
  }
}

// Line Chart Overview
.linechart-overview-wrap {
  .d-flex {
    @include xxl {
      flex-flow: column;
      align-items: flex-start !important;
    }

    &:not(:last-child) {
      .border-line-chart {
        @include xxl {
          margin-bottom: 10px !important;
        }
      }
    }
  }

  .border-line-chart {
    max-width: 220px;

    @include xxl {
      width: 100%;
      max-width: 100%;
    }
  }
}

.top-menu {
  .linechart-overview-wrap {
    .mb-3 {
      margin-bottom: 28px !important;

      @include xxl {
        margin-bottom: 1rem !important;
      }
    }
  }
}

/* chart doughnut legend */
.session-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 18px 0;

  &.session-wrap--2{
    justify-content: space-between;
    padding:0 15px;
    min-height: 76px;
    background: #f8f9f8;
    border-radius: 8px;
    margin-bottom: 0;
    .session-single {
      strong{
        font-weight: 600;
      }
      .chart-label{
        font-size:15px;
        font-weight: 400;
        line-height: lh(15px, 25px);
        color: var(--color-gray);
        margin-bottom: 0;
      }
    }
  }

  &.session-wrap--top{
    margin: 18px 0 0 0;
    .session-single .chart-label{
      margin-bottom: 2px;
      font-size: 15px;
      font-weight: 500;
      line-height: lh(15px, 20px);
      color: var(--color-dark);
    }
    
    &--2{
      margin: 25px 0 10px 0;
    }
    &--4{
      margin:8px 0 -4px 0;
    }
    &--5{
      margin: 18px 0 0 0;
    }

  }

  @include md {
    margin: 10px 0 -5px;
  }

  .session-single {
    text-align: center;
    padding: 5px 15px;

    @include xl {
      padding: 15px;
    }

    @include md {
      padding: 10px;
    }
    @include ssm{
      padding: 5px;
    }

    .chart-label {
      display: flex;
      align-items: center;
      color: var(--color-dark);
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 5px;
      &.new{
        color: var(--color-gray);
        font-size: 14px;
        font-weight: 400;
      }
    }

    strong {
      font-weight: 500;
     @include rfs(18px);
      color: var(--color-dark);
    }

    sub {
      bottom: 0;
      font-size: 13px;
      color: var(--color-lighten);
    }
  }
}

.label-dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 9px;
  &.dot-primary {
    background-color:var(--color-primary);
   }
 
   &.dot-secondary {
    background-color:var(--color-secondary);
   }

  &.dot-success {
   background-color:var(--color-success);
  }

  &.dot-info {
   background-color:var(--color-info);
  }

  &.dot-warning {
   background-color:var(--color-warning);
  }
}

/* Revenue Chart */
.revenue-labels {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px 20px;

  div {
    padding: 25px;
    @include sm{
      padding-bottom: 0;
    }
  }

  strong {
    @include rfs(24px);
    display: block;
    font-weight: 600;
    color: var(--color-dark);
  }

  span {
    font-size: 14px;
    color: var(--color-lighten);
    display: block;
    position: relative;
    padding-left: 17px;

    &:before {
      position: absolute;
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #c6d0dc;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.revenue-chart-legend-list {
  margin-top: 25px;
}

.revenue-chart-legend {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @include e("label") {
    min-width: 80px;

    span {
      font-size: 14px;
      color:var(--color-gray);
    }
  }

  @include e("data") {
    span {
     @include rfs(18px);
      font-weight: 500;
      color: var(--color-dark);
    }
  }

  @include e("percentage") {
    span {
      font-size: 13px;
      color: var(--color-lighten);
    }
  }
}

.revenue-pieChart-wrap {
  margin-top: 5px;
}

.crm {
  .revenue-labels strong {
    padding-bottom: 8px;
  }

  .cashflow-display__amount {
    padding-top: 8px;
  }

  .revenue-labels .current span:before {
   background-color: #c6d0dc;
  }

  .dropdown {
    img,
    svg {
      width: 18px;
    }
  }
  .selling-table-wrap {
    .table--default tr th {
      &:first-child {
        padding-left: 15px;
      }
  
      &:last-child {
        padding-right: 15px;
      }
    }
  
    .table--default tr td {
      &:first-child {
        padding-left: 0;
      }
  
      &:last-child {
        padding-right: 15px;
      }
    }
    table{
      thead {
        tr {
          background: var(--bg-normal);
        }
      }
    }
  }
}

.t-thead-bg{
  .selling-table-wrap {
    .table--default tr th {
      &:first-child {
        padding-left: 15px;
      }
  
      &:last-child {
        padding-right: 15px;
      }
    }
  
    .table--default tr td {
      &:first-child {
        padding-left: 0;
      }
  
      &:last-child {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    table{
      thead {
        tr {
          background: var(--bg-normal);
        }
      }
    }
  }
}


/* Revenue Chart 3 */
.revenue-chart-box{
  @include sm{
    text-align: center;
  }
}
.revenue-chart-box-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.overview-content3 {
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  .revenue-chart-box__Icon {
    img,
    svg {
      width: 32px;
    }
    i{
      @include rfs(30px);
    }
  }

}

.revenue-chart-box__Icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 10px;
  @extend .content-center;
  margin-right: 8px;
  img,
  svg {
    width: 25px;
  }
  i{
    @include rfs(30px);
  }

  &.order-bg-opacity-primary {
    svg,i {
      color:var(--color-primary);
    }
  }
  &.order-bg-opacity-secondary {
    svg,i {
      color:var(--color-secondary);
    }
  }

  &.order-bg-opacity-success {
    svg,i {
     color:var(--color-success);
    }
  }

  &.order-bg-opacity-warning {
    svg,i {
      color:var(--color-warning);
    }
  }
}

.revenue-chart-box__data {
  @include ofs(20px, lh(20px, 24px), 500);
  color: var(--color-dark);
}

.revenue-chart-box__label {
  @include ofs(14px, lh(14px, 24px), 400);
  color: var(--color-gray-x);
  margin-top: 8px;
}

/* Cash Flow Chart */
.cashflow-chart {
  .legend-static {
    margin-top: 12px;
  }
}

/* Forcast Card */
.forcast-cardbox {
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  background-color:var(--color-white);

  .forcast-title {
    font-weight: 500;
    padding: 25px 25px 30px;
  }

  .forcast-details {
    padding: 0 25px 0 25px;
    margin-bottom: 24px;

    .forcast-value {
      @include rfs(30px);
      margin-bottom: 6px;
    }

    .forcast-status {
      .percentage {
        font-size: 14px;
        font-weight: 500;

        svg,
        img {
          width: 14px;
        }
      }

      .forcast-text {
        font-size: 13px;
        margin-left: 4px;
        color: var(--color-light);
      }
    }
  }
}

/* ration box */
.ratio-box {
  .ratio-title {
    font-weight: 500;
    margin-bottom: 30px;
  }

  .ratio-info {
    margin-bottom: 12px;

    .ratio-point {
      @include rfs(36px);
    }

    .ratio-percentage {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .progress {
    height: 6px;
  }

  .progress-text {
    display: inline-block;
    font-size: 14px;
    margin-top: 12px;

    .dark {
      font-weight: 500;
    }

    .progress-target {
      color: var(--color-light);
      font-size: 13px;
      margin-left: 4px;
    }
  }
}

.top-menu {
  .ratio-box {
    min-height: 245px;

    @include xxl {
      min-height: 100%;
    }
  }
}

/* Income Expense */
.inEx-wrap {
  @include xxl {
    flex-flow: column;
  }

  .inEx-box {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 25px 0;
    border-right: 1px solid var(--border-color);

    @include xxl {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 25px 0 0;
      border-right: 0 none;
    }

    @include ssm {
      justify-content: center;
      margin-bottom: 30px;
    }
  }

  .inEx-chart {
    flex: 0 0 75%;
    max-width: 75%;
    padding: 25px;

    @include xxl {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 0 18px;
    }

    .legend-static {
      margin-top: 12px;

      li {
        @include ssm {
          display: flex !important;
        }

        &:not(:last-child) {
          margin-right: 22px;

          @include ssm {
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }
}

.inEx {
  @include e("single") {
    @include ssm {
      text-align: center !important;
    }

    @include xxl {
      flex: 0 0 25%;
    }

    @include lg {
      flex: 0 0 50%;
    }

    @include ssm {
      flex: 0 0 100%;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .inEx-subtitle {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 8px;
      color: var(--color-light);
    }

    .inEx__info {
      @include xxl {
        flex-flow: column;
        align-items: flex-start !important;
      }

      @include ssm {
        align-items: center !important;
      }
    }
  }

  @include e("info") {
    .inEx__percentage {
      font-size: 14px;
      font-weight: 500;
      margin: 0 8px -2px 10px;

      @include xxl {
        display: inline-block;
        margin: 15px 8px 0 0;
      }

      svg,
      img {
        width: 14px;
        margin-right: 4px;
      }
    }

    .inEx-tail {
      font-size: 13px;
      color: var(--color-light);
    }
  }
}

/* Cash Flow */
.cashflow-display {
  margin: -20px -20px 10px;

  @include ssm {
    margin: -10px -10px 15px;
  }

  @include ssm {
    flex-flow: column;
    align-items: center;
  }

  @include e("single") {
    margin: 20px;

    @include ssm {
      text-align: center;
      margin: 10px;
    }
  }

  @include e("title") {
    font-size: 14px;
    color: var(--color-light);
  }

  @include e("amount") {
    margin-top: 6px;
  }
}

.cashflow-display2 {
  padding: 17px 0 5px 6px;
}

// Region Map
.regions-svg {
  width: 100%;
  height: 100%;
  min-height: 160px;

  .jvectormap-container {
    width: 100%;
    height: 100%;
    text-align: center;
    min-height: 160px;
    @include ssm{
      svg{
        width: 100% !important;
      }
    }
  }
}


// Table selling Content

.crm {
  .selling-table-wrap{
    .table--default tbody tr:last-child{
      border-bottom: none;
    }
  }

  .table-selling-content {
    display: flex;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      margin-right: 12px;
      border-radius: 50%
    }

    .title {
      @include ofs(14px, lh(14px, 20px), 600);
      color: var(--color-dark);
    }

    span {
      @include ofs(13px, lh(13px, 20px), 400);
      color: #9297AF;
    }


  }
}

.deals-table-wrap {
  padding-bottom: 9px;

  .table--default {
    td {
      padding: 8px 25px;
      @include ofs(14px, null, 600);
      color: var(--color-dark);
      border-top: none;
      white-space: nowrap;
    }

    tbody tr:first-child {
      td {
        padding-top: 23px;
      }
    }

    tbody tr:last-child {
      td {
        padding-bottom: 17px;
      }
    }
  }
}

.sales .revenue-labels div {
  padding: 17px 0 0 25px;
}

.top-circle {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 0;
  bottom: 87px;
  overflow: hidden;
 background-color:var(--color-success);
  top: 88%;
  left: 12%;
  transform: translate(-50%, -50%);
}


.selling-badge{
  @extend .content-center;
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  @include ofs(12px, lh(12px,20px), 500);
  width:max-content;
  text-transform: capitalize;
}



/* Chart label detail */
.label-detailed {
  display: flex;
  justify-content: center;
  margin: -16px -12px -5px;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 11px;
  @include sm{
    margin-bottom: 0;
    gap: 0;
  }
  &--two{
    margin: 0 -12px -5px;
    .label-detailed__total{
      @include rfs(18px);
    }
  }
  &__single {
    padding: 5px 8px;
    display: flex;
    align-items: center;
    span,
    strong {
      padding: 0 5px;

      &.label-detailed__status {
        padding-left: 0;
      }
    }
  }
  &__type {
    font-size: 14px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    color: var(--color-gray);
    &::before {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #eee;
      display: inline-block;
      margin-right: 5px;
    }
    &--primary {
      &::before {
        background: var(--color-primary);
      }
    }
    &--info {
      &::before {
        background: var(--color-info);
      }
    }
  }
  &__total {
    font-size: 22px;
    font-weight: 600;
    color:var(--color-dark);
  }
  &__status {
    display: inline-flex;
    align-items: center;
    line-height: normal;
    i {
      font-size: 11px;
    }
    img,svg{
      width: 11px;
      height: 11px;
      margin-left: 5px;
      + strong{
        margin-left: 5px;
      }
    }
    strong {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.revenueSourceChart{
  padding: 25px 25px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sm{
    flex-wrap: wrap;
  }
  @include MinSm{
    &.revenuePieChart--wrapper{
      .revenuePieChart{
        margin-left: -21px;
      }
      .chart-content__details{
        margin-left: 30px;
      }
    }
  }	
  .chart-content__details {
    display: flex;
    align-items: center;
    @include sm{
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .chart-content__single {
    text-align: center;
    span {
      display: block;
      &.icon {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 22px 22px 0;
        border-radius: 10px;
        @include xs{
          width:60px;
          height: 60px;
          margin: 15px 15px 0;
        }
        svg {
          width: 30px;
        }
        span,
        i{
          font-size: 30px;
        }
        &.color-facebook {
          background: rgba(var(--color-primary-rgba), 0.2);
          color: var(--color-primary);
        }
        &.color-secondary {
          background: rgba(var(--color-secondary-rgba), 0.2);
          color: var(--color-secondary);
        }
        &.color-twitter {
          background: rgba(var(--color-twitter-rgba), 0.2);
          color: var(--color-twitter);
        }
      }
      &.label {
        font-weight: 500;
        font-size: 15px;
        margin-top: 7px;
        color: var(--color-dark);
        text-transform: capitalize;
      }
      &.data {
        font-weight: 500;
        font-size: 14px;
        margin-top: 2px;
        color: var(--color-gray);
      }
    }
  }
}

/* Performance overview */
.performance-o{
  height: 100%;
  .card-body{
    display: flex;
    align-items: center;
    @include xl{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .performance-chart-labels{
    @include xl{
      margin: 0 !important;
      ul{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 15px;
        li{
          margin: 0 !important;
        }
      }
    }
    @include xs{
      ul{
        flex-wrap: wrap;
        justify-content: center
      }
    }
  }
}
.performance-chart-wrap {
  display: flex;
  align-items: center;
}
.performance-chart {
  width: 285px;
  height: 285px;
  margin: -25px;
}
.performance-chart-labels {
  margin-left: 38px;
  ul {
    li {
      font-size: 15px;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: var(--color-gray);
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &::before {
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #ccc;
        margin-right: 7px;
      }
      &.label-target {
        color:var(--color-gray) ;
        &::before {
          background: var(--color-primary);
        }
      }
      &.label-completed {
        &::before {
          background: var(--color-info);
        }
      }
      &.label-in-progress {
        &::before {
          background: var(--color-warning);
        }
      }
    }
  }
}