/*------------------------------------*\
    #-CSS Variables
\*------------------------------------*/
:root {

    // Global color
    --body:#f4f5f7;
    --contents-bg:#f4f5f7;
    --main-color:white;

    //chart border
    --accent:#ffffff;

    /* Menu color*/
    --color-menu: #{$gray};
    --color-menu-hover: #{$secondary};
    --color-menu-active: #{$secondary};
    --color-menu-bg-opacity: rgb(var(--color-primary-rgba), .10);

    // Header & Sidebar
    --header-bg: white;
    --sidebar-bg: white;
    --main-content:var(--contents-bg);
    --header-bg:white;
    --logo-wrapper-bg:white;


    // button color
    --color-add-listing-button-text: #{$white};
    --color-add-listing-button-text-hover: #{$white};
    --bgcolor-add-listing-button: #{$primary};
    --bgcolor-add-listing-button-hover: #{$dark};

    //Banner Color
    --color-banner_title: #{$white};
    --color-breadcrumb-link: #{$light};
    --color-breadcrumb-link-hover: #{$secondary};
    --color-breadcrumb_separator: #{$light};

    //Footer color
    --bgcolor-footer: #{$footer-dark};
    --color-footer-divider: #{$footer-dark};
    --color-footer-title: #{$white};
    --color-footer-link-hover: #{$white};

    // Main Color
    --color-primary: #8231D3;
	--color-secondary: #5840FF;
	--color-success: #01B81A;
	--color-info: #00AAFF;
	--color-warning: #FA8B0C;
	--color-danger: #FF0F0F;
	--color-dark: #0A0A0A;

    --color-infos:#00E4EC;
    --color-warnings:#FFBB00;
    --color-dangers: #FF0F0F;
    --color-purple:#A722F6;

     //Main Bg color
     --bg-primary: #8231D3;
     --bg-secondary: #5840FF;
     --bg-success: #01B81A;
     --bg-info: #00AAFF;
     --bg-warning: #FA8B0C;
     --bg-danger: #FF0F0F;
     --bg-dark: #0A0A0A;

    // Hover Color
    --bg-primary-hover: #6726A8;
	--bg-secondary-hover: #3520C8;
	--bg-success-hover: #0D811D;
	--bg-info-hover: #0787C7;
	--bg-warning-hover: #D9790A;
	--bg-danger-hover: #CB0000;
	--bg-dark-hover: #272525;
	--bg-gray-hover: #585858;

    --color-primary-rgba: 130, 49, 211;
	--color-secondary-rgba: 88, 64, 255;
	--color-success-rgba: 1, 184, 26;
	--color-info-rgba: 0, 170, 255;
	--color-warning-rgba: 250, 139, 12;
	--color-danger-rgba: 255, 15, 15;
	--color-dark-rgba: 9, 14, 48;
    --color-primary-rgba-shadow: rgba(var(--color-primary-rgba), 0.20);

    //Side Color
    --color-white: #fff;
	--color-light: #747474;
	--color-lighten: #A0A0A0;
	--color-lighter: #F8F9FB;
	--color-lighten-x: #5d658c;
    --footer-dark: #353b58;
	--color-deep: #eff0f3;
	--color-normal: #f8f9fb;
	--color-lighters: #f4f5f7;

    --color-lighten-rgba:173, 181, 217;

    --color-gray: #404040;
	--color-gray-x: #5A5F7D;
	--body-color: #666d92;
	--text-color: #666d92;
	--light-gray: #8C90A4;
	--light-gray-rgba: 173, 181, 217;

    //border color
    --border-color: #F1F2F6;
	--border-light: #E3E6EF;
	--border-deep: #c6d0dc;

    // bg Color
    --bg-normal: #f8f9fb;
	--bg-lighters: #f4f5f7;
	--bg-lighter: #f4f5f7;
	--bg-deep: #EFF0F3;
	--bg-gray: #404040;
	--bg-light: #747474;
	--bg-white: #ffffff;

    --bg-light-rgba: 116, 116, 116;
	--bg-normal-rgba: 248, 249, 251;
	--bg-lighter-rgba: 244, 245, 247;
	--bg-deep-rgba:239, 240, 243;
	--color-gray-rgba: 173, 181, 217;

    // Rgba Color
    --color-dark-rgba: 39, 43, 65;
	--color-white-rgba: 255, 255, 255;
	--box-shadow-color-rgba: 146, 153, 184;

    // Radius Size
    --border-radius-xl: 10px;
    --border-radius-md: 6px;
    --border-radius-xs: 4px;

    // social color
    --color-facebook: #2366b8;
    --color-twitter: #00abe4;
    --color-pinterest: #ef1b25;
    --color-gplus: #dc4e41;
    --color-linkedin: #007cbc;
    --color-tumblr: #35465c;
    --color-vk: #4a76a8;
    --color-youtube: #e32212;
    --color-instagram: #9c27b0;
    --color-ruby: #c2185b;

    --color-facebook-rgba: 35, 102, 184;
    --color-twitter-rgba: 0, 171, 228;
    --color-pinterest-rgba: 239, 27, 37;
    --color-gplus-rgba: 220, 78, 65;
    --color-linkedin-rgba: 0, 124, 188;
    --color-tumblr-rgba: 53, 70, 92;
    --color-vk-rgba: 74, 118, 168;
    --color-youtube-rgba: 227, 34, 18;
    --color-instagram-rgba: 156, 39, 176;
    --color-ruby-rgba: 194, 24, 91;
    --color-infos-rgba:0, 228, 236;
    --color-warnings-rgba:255, 187, 0;
    --color-dangers-rgba: 255, 15, 15;
    --color-purple-rgba:167, 34, 246;


    //chart js
    --color-primary-rgba-10:rgba(var(--color-primary-rgba), 0.1);
    --color-secondary-rgba-10:rgba(var(--color-secondary-rgba), 0.1);
    --color-primary-rgba-50:rgba(var(--color-primary-rgba), 0.5);
    --color-secondary-rgba-50:rgba(var(--color-secondary-rgba), 0.5);

    //transition
    --transition:all 0.3s ease;

    // chart

    --gridLines_color:#E3E6EF;

    //scrollbar
    --scrollbarBG: var(--color-white);
    --thumbBG: #E3E6EF;

    // input
    --input-bg:var(--color-white);
    --input-focus-bg:var(--color-white);

    // tooltip
    --tooltip-bg:var(--color-white);
    --tooltip-arrow-color:var(--color-white);

    // shadow
    --shadow2:#9299B80D;
    --shadow3:#9299B81A;
    --shadow4:#9299B833;
    --shadow5:#9299b826;

}


.layout-dark{

    // Global color
    --body:var(--main-color);
    --contents-bg:var(--main-color);

    --white-100:white;
    --white-87:rgb(255, 255, 255, .87);
    --white-60:rgb(255, 255, 255, .60);
    --white-30:rgb(255, 255, 255, .30);
    --white-20:rgb(255, 255, 255, .20);
    --white-10:rgb(255, 255, 255, .10);
    --white-06:rgb(255, 255, 255, .06);
    --main-color:#010413;

    /* Menu color*/
    --color-menu: #{$gray};
    --color-menu-hover: #{$secondary};
    --color-menu-active: #{$secondary};
    --color-menu-bg-opacity: rgb(251, 53, 134, .10);

    // Header & Sidebar
    --header-bg: white;
    --sidebar-bg: white;
    --main-content:var(--bg-normal);
    --logo-wrapper-bg:var(--white-10);
    --header-bg:var(--white-10);


    // button color
    --color-add-listing-button-text: #{$white};
    --color-add-listing-button-text-hover: #{$white};
    --bgcolor-add-listing-button: #{$primary};
    --bgcolor-add-listing-button-hover: #{$dark};

    //Banner Color
    --color-banner_title: #{$white};
    --color-breadcrumb-link: #{$light};
    --color-breadcrumb-link-hover: #{$secondary};
    --color-breadcrumb_separator: #{$light};

    //Footer color
    --bgcolor-footer: #{$footer-dark};
    --color-footer-divider: #{$footer-dark};
    --color-footer-title: #{$white};
    --color-footer-link-hover: #{$white};

    // Main Color
    --color-primary: #8231D3;
	--color-secondary: #5840FF;
	--color-success: #01B81A;
	--color-info: #00AAFF;
	--color-warning: #FA8B0C;
	--color-danger: #FF0F0F;
	--color-dark: var(--white-87);

    --color-infos:#00E4EC;
    --color-warnings:#FFBB00;
    --color-dangers: #FF0F0F;
    
    --color-purple:#A722F6;

     //Main Bg color
     --bg-primary: #8231D3;
     --bg-secondary: #5840FF;
     --bg-success: #01B81A;
     --bg-info: #00AAFF;
     --bg-warning: #FA8B0C;
     --bg-danger: #FF0F0F;
    --bg-dark: var(--white-87);

    // Hover Color
    --bg-primary-hover: #6726A8;
	--bg-secondary-hover: #3520C8;
	--bg-success-hover: #0D811D;
	--bg-info-hover: #0787C7;
	--bg-warning-hover: #D9790A;
	--bg-danger-hover: #CB0000;
	--bg-dark-hover: #272525;
	--bg-gray-hover: #585858;


    --color-primary-rgba: 130, 49, 211;
	--color-secondary-rgba: 88, 64, 255;
	--color-success-rgba: 1, 184, 26;
	--color-info-rgba: 0, 170, 255;
	--color-warning-rgba: 250, 139, 12;
	--color-danger-rgba: 255, 15, 15;
	--color-dark-rgba: 9, 14, 48;
    --color-primary-rgba-shadow: rgba(var(--color-primary-rgba), 0.20);
    

    //Side Color
    --color-white: var(--white-87);
    --color-gray: var(--white-60);
    --color-light: var(--white-60);
    --color-lighten: var(--white-60);
	--color-lighter: #F8F9FB;
	--color-lighten-x: #5d658c;
    --footer-dark: #353b58;
	--color-deep: #eff0f3;
	--color-normal: #f8f9fb;
	--color-lighters: #f4f5f7;

    --color-lighten-rgba:173, 181, 217;
    --color-gray-x: var(--white-60);
    --body-color: var(--white-60);
    --text-color: var(--white-60);
    --light-gray: var(--white-60);
    --light-gray-rgba: 140, 144, 164;

    //border color
    --border-color: var(--white-06);
    --border-deep: var(--white-06);
    --border-color: var(--white-06);
    --border-light:var(--white-06);

    // bg Color
    --bg-normal: var(--white-10);
    --bg-lighters: var(--white-10);
    --bg-lighter: var(--white-10);
    --bg-deep: var(--white-10);
    --bg-white:var(--white-10);

    --bg-normal-rgba: 248, 249, 251;
    --bg-lighter-rgba: 244, 245, 247;
    --bg-deep-rgba: 239, 240, 243;
    --color-gray-rgba: 140, 144, 164;

    // Rgba Color
    --color-dark-rgba: #{39, 43, 65};
    --color-white-rgba: #{255,255,255};
    --box-shadow-color-rgba:146, 153, 184;
    --main-color-rgba:1, 4, 19;

    // Radius Size
    --border-radius-xl: 10px;
    --border-radius-md: 6px;
    --border-radius-xs: 4px;

    // social color
    --color-facebook: #2366b8;
    --color-twitter: #00abe4;
    --color-pinterest: #ef1b25;
    --color-gplus: #dc4e41;
    --color-linkedin: #007cbc;
    --color-tumblr: #35465c;
    --color-vk: #4a76a8;
    --color-youtube: #e32212;
    --color-instagram: #9c27b0;
    --color-ruby: #c2185b;

    --color-facebook-rgba: 35, 102, 184;
    --color-twitter-rgba: 0, 171, 228;
    --color-pinterest-rgba: 239, 27, 37;
    --color-gplus-rgba: 220, 78, 65;
    --color-linkedin-rgba: 0, 124, 188;
    --color-tumblr-rgba: 53, 70, 92;
    --color-vk-rgba: 74, 118, 168;
    --color-youtube-rgba: 227, 34, 18;
    --color-instagram-rgba: 156, 39, 176;
    --color-ruby-rgba: 194, 24, 91;


    //chart js
    --color-primary-rgba-10:rgba(var(--color-primary-rgba), 0.1);
    --color-secondary-rgba-10:rgba(var(--color-secondary-rgba), 0.1);
    --color-primary-rgba-50:rgba(var(--color-primary-rgba), 0.5);
    --color-secondary-rgba-50:rgba(var(--color-secondary-rgba), 0.5);
    

    //transition
    --transition:all 0.3s ease;

    // chart
    --gridLines_color:rgb(255, 255, 255, .30);

    --scrollbarBG: #1b1e2b;
    --thumbBG:rgb(255, 255, 255, .30);

    // input
    --input-bg:var(--white-10);
    --input-focus-bg:var(--white-10);

    // tooltip
    --tooltip-bg:var(--main-color);
    --tooltip-arrow-color:var(--main-color);

    // shadow
    --shadow2:#01041333;
    --shadow3:#01041366;
    --shadow4:#01041399;
    --shadow5:#01041399;
}